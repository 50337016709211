<template>
  <a-card>
    <a-page-header
      :title='"广告主平台账套管理"'
    />
    <a-form-model
      ref="form"
      :model="form"
      v-bind='layout'
    >
      <a-row>
        <a-col :span="7">
          <a-form-model-item label="广告主">
            <a-input
              v-model="form.advertiserName"
              placeholder="请输入"
            />
          </a-form-model-item>
        </a-col>
        <a-col :span="1"></a-col>
        <a-col :span="7">
          <a-form-model-item label="广告平台">
            <a-select
              mode="multiple"
              v-model="adPlatformIdList"
              placeholder="请选择"
              style="width: 100%;"
              allow-clear
              show-search
              option-filter-prop="children"
            >
              <a-select-option v-for="item in platformList" :key="item.id">{{ item.adPlatformName }}</a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="1"></a-col>
        <a-col :span="8">
          <a-form-model-item label="授权有效性">
            <a-select
              v-model="form.authStatus"
              placeholder="请选择"
              style="width: 100%;"
            >
              <a-select-option key="1">已授权</a-select-option>
              <a-select-option key="2">已失效</a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="7">
          <a-form-model-item label="托管平台帐套数">
            <a-input-number
              v-model="form.platformAccountNumber"
              placeholder="请输入"
              style="width: 100%;"
              :min="0"
            />
          </a-form-model-item>
        </a-col>
        <a-col :span="1"></a-col>
        <a-col :span="7">
          <a-form-model-item label="最后操作人">
            <a-input
              v-model="form.actor"
              placeholder="请输入"
            />
          </a-form-model-item>
        </a-col>
        <a-col :span="1"></a-col>
        <a-col :span="8">
          <a-form-model-item label="最后操作时间">
            <a-range-picker
              :value='dateValue'
              :format='date_format'
              :placeholder="['开始时间', '结束时间']"
              @change='onChangeDate'
            />
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="7">
          <a-form-model-item label="广告主状态">
            <a-select
              v-model="form.status"
              placeholder="请选择"
              style="width: 100%;"
            >
              <a-select-option key="1">开启</a-select-option>
              <a-select-option key="2">关闭</a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col class="flex">
          <a-space>
            <div><span class="red">PS：</span>若广告主完成授权但未查询到广告主，建议查看广告主授权</div>
            <a-button @click="handleReset">重置</a-button>
            <a-button type="primary" @click="handleSearch">查询</a-button>
          </a-space>
        </a-col>
      </a-row>
    </a-form-model>
    <a-row style="margin:0 0 30px 60px">
      <a-col>
        <a-space>
          <span style="font-weight: bold;font-size: 14px">更新巨量广告平台账套：</span>
          <a-input
            v-model.trim="userId"
            placeholder="请输入巨量引擎userId"
            style="padding: 0 10px;width: 260px"
          />
        </a-space>
        <a-button type="primary" @click="submitReHandle" style="margin-left: 10px">提交</a-button>
      </a-col>
    </a-row>

    <a-table
      :loading="loading"
      :columns="columns"
      :data-source="dataList"
      :row-key="(record, index) => index"
      :pagination='false'
    >
      <div slot="action" slot-scope="text, record">
        <a @click="jumpSettingPage(record)">设置托管帐套</a>
      </div>
    </a-table>

    <base-pagination
      :currentPage="pagination.current"
      :pageSize="pagination.pageSize"
      :total="pagination.total"
      @onChange="handlePaginationChange"
      @onShowSizeChange="handlePaginationChange"
    />
  </a-card>
</template>

<script>
export default {
  data() {
    return {
      layout: {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
      },
      form: {
        advertiserName: this.$route.query.advertiserName || '',
        status: '1',
      },
      adPlatformIdList: [],
      dateValue: [],
      date_format: 'YYYY-MM-DD',
      loading: false,
      columns: [
        {
          align: 'center',
          title: '广告主编码',
          dataIndex: 'advertiserCode',
          customRender(text) {
            return text || '-';
          },
        },
        {
          align: 'center',
          title: '广告主',
          dataIndex: 'advertiserName',
          customRender(text) {
            return text || '-';
          },
        },
        {
          align: 'center',
          title: '广告平台',
          dataIndex: 'adPlatformName',
          customRender(text) {
            return text || '-';
          },
        },
        {
          align: 'center',
          title: '授权有效性',
          // dataIndex: 'authStatus',
          customRender: (text, row) => {
            let txt = <div class="red">已失效</div>;
            return row.authStatus == 1 ? '已授权' : txt;
          },
        },
        {
          align: 'center',
          title: '托管平台账套数',
          dataIndex: 'platformAccountNumber',
          customRender(text) {
            return text || '-';
          },
        },
        {
          align: 'center',
          title: '操作',
          key: 'action',
          scopedSlots: { customRender: 'action' },
        },
        {
          align: 'center',
          title: '最后操作人',
          dataIndex: 'actor',
          customRender(text) {
            return text || '-';
          },
        },
        {
          align: 'center',
          title: '最后操作时间',
          dataIndex: 'atime',
          customRender(text) {
            return text || '-';
          },
        },
      ],
      dataList: [],
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
      },
      platformList: [],
      userId: '',
    };
  },
  created() {
    this.getDataList();
    this.getAdPlatformList();
  },
  methods: {
    getAdPlatformList() {
      this.$api.core.pphtManage.getAdPlatformList().then(res => {
        if (res.code == 200) {
          this.platformList = res.data;
        } else {
          console.error(`获取平台列表失败，${ res.message }`);
        }
      });
    },
    //日期选择框
    onChangeDate(dates, dateStrings) {
      this.dateValue = [dates[0], dates[1]];
      this.form.atimeStart = dateStrings[0];
      this.form.atimeEnd = dateStrings[1];
    },
    handleSearch() {
      this.pagination.current = 1;
      this.getDataList();
    },
    handleReset() {
      this.dateValue = [];
      this.form = {
        advertiserName: this.$route.query.advertiserName || '',
        status: '1',
      };
      this.pagination.current = 1;
      this.pagination.pageSize = 10;
      this.adPlatformIdList = [];
      this.getDataList();
    },
    handlePaginationChange(current, pageSize) {
      this.pagination.current = current;
      this.pagination.pageSize = pageSize;
      this.getDataList();
    },
    getDataList() {
      this.loading = true;
      let params = Object.assign(this.form, {
        adPlatformIdStr: this.adPlatformIdList.length > 0 ? this.adPlatformIdList.toString() : undefined,
        page: this.pagination.current,
        size: this.pagination.pageSize,
      });
      this.$api.core.pphtManage.getPlatformAccountList(params).then((res) => {
        this.loading = false;
        if (res.code == 200) {
          this.dataList = res.data.list || [];
          this.pagination.total = res.data.total || 0;
        } else {
          this.dataList = [];
          this.pagination.total = 0;
          console.error(`获取列表失败${ res }`);
        }
      });
    },
    jumpSettingPage(record) {
      this.$router.push({
        path: `/pphtManage/platformSetting`,
        query: {
          advertiserId: record.advertiserId,
          adPlatformType: record.adPlatformType,
          name: record.advertiserName,
        },
      });
    },
    submitReHandle() {
      if (!this.userId) {
        this.$message.warning('userId不能为空!');
        return;
      }
      this.$api.core.pphtManage.submitReHandle(this.userId).then(res => {
        if (res.code == 200) {
          this.$message.success('更新成功!');
        } else {
          this.$message.error(`更新失败，${ res.message }`);
        }
      });
    },
  },
};
</script>

<style scoped lang="less">
/deep/ .ant-page-header {
  padding-left: 0;
  padding-right: 0;
}

.flex {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 40px;
}
</style>
